import { Alert, Card, Flex } from 'antd';
import { find, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataNotFound, ReloadButton } from '../../components/common';
import { resultActions } from '../../config/actions';
import usePrevious from '../Hooks/usePrevious';
import MyResult from './MyResult';

function Result() {
  const dispatch = useDispatch();
  const { results, loading, loadError } = useSelector((state) => state.result);
  const { currentStudentProgramme } = useSelector((state) => state.auth);
  const [myResult, setMyResult] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const prevState = usePrevious({ loadError });

  const getMyResult = () => {
    setErrorMessage(null);
    dispatch(resultActions.getMyResult(currentStudentProgramme?.id));
  };

  const getResults = () => {
    let studentResults = [];

    if (results && !isEmpty(currentStudentProgramme)) {
      const findResult = find(
        results,
        (result) => result?.studentProgrammeId === currentStudentProgramme?.id
      );

      studentResults = findResult?.data || {};
    }

    return studentResults;
  };

  useEffect(() => {
    if (results) {
      const findResult = getResults();
      if (isEmpty(findResult)) getMyResult();
      setMyResult(findResult);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(results)) {
      const findResult = getResults();

      if (findResult) setMyResult(findResult);
      setErrorMessage(null);
    }
  }, [results, loading]);

  useEffect(() => {
    if (
      !isEmpty(prevState) &&
      !isEmpty(loadError) &&
      loadError !== prevState.loadError
    ) {
      setErrorMessage(loadError?.error?.message || loadError?.server?.message);
    }
  }, [loadError]);

  return (
    <Card
      loading={loading && isEmpty(myResult)}
      title={
        <Flex justify="space-between" align="center" className="py-2 ">
          <div className="font600 text-uppercase text-primary">My Result</div>
          <div className="card-options">
            <ReloadButton loading={loading} onClick={getMyResult} />
          </div>
        </Flex>
      }
    >
      <div>
        {errorMessage && <Alert type="error" description={errorMessage} />}

        {isEmpty(myResult) ? (
          <DataNotFound message="You have No Results" />
        ) : (
          <MyResult result={myResult} />
        )}
      </div>
    </Card>
  );
}

export default Result;
