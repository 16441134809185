import {
  CaretDownOutlined,
  LoadingOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Layout, Menu, Space } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { authActions, settingActions } from '../../config/actions';
import MenuList from '../../config/routes/MenuList';
import Avatar from '../../containers/MyProfile/Avatar';
import ToggleMenu from './ToggleMenu';

const { Header } = Layout;
const { SubMenu } = Menu;

function MainHeader() {
  const dispatch = useDispatch();
  const { shouldAllocate } = useSelector((state) => state.myInvoice);
  const { forceEnrollment } = useSelector((state) => state.enrollment);
  const { authUser, loggingOut, gettingAuthUser, activeProgramme } =
    useSelector((state) => state.auth);
  const { selectedMenu } = useSelector((state) => state.setting);
  const isMobileDevice = useMediaQuery({ maxWidth: 767 });

  const setSelectedMenu = (menu) => {
    dispatch(settingActions.setSelectedMenu(menu));
  };

  const actionButtons = [
    {
      title: 'VIEW BIO DATA',
      action: MenuList.BioData,
      id: 1,
    },
    {
      title: 'VIEW RESULTS',
      action: MenuList.MyResults,
      id: 2,
    },
    {
      title: 'VIEW INVOICES',
      action: MenuList.MyInvoice,
      id: 3,
    },
    {
      title: 'VIEW FEES STRUCTURE',
      action: MenuList.myFeesStructure,
      id: 4,
    },
    {
      title: 'Generate PRN',
      action: MenuList.PaymentReference,
      id: 5,
    },
  ];

  const items = [
    {
      label: 'Profile',
      key: 0,
      className: 'text-capitalize fw-bold py-3 text-sm',
      onClick: () => setSelectedMenu(MenuList.BioData),
      icon: <UserOutlined />,
    },
    {
      label: 'Services',
      key: 1,
      className: 'text-capitalize fw-bold py-3 text-sm',
      onClick: () => setSelectedMenu(MenuList.Services),
      icon: <SettingOutlined />,
    },
    {
      type: 'divider',
    },
    {
      label: 'Logout',
      key: 3,
      danger: true,
      className: 'text-capitalize fw-bold py-3 text-sm',
      onClick: () => dispatch(authActions.logoutUser()),
      icon: loggingOut ? <LoadingOutlined /> : <LogoutOutlined />,
    },
  ];

  return (
    <>
      <Header theme="light" className="bg-white border-bottom ps-3 py-0 pe-3">
        <ToggleMenu />
        {!isMobileDevice && (
          <Space.Compact size="small">
            {!isEmpty(activeProgramme) &&
              actionButtons.map((button) => (
                <Button
                  size="small"
                  type={
                    selectedMenu.action === button.action.action
                      ? 'primary'
                      : 'default'
                  }
                  className={`${
                    button.id === 5 ? 'ms-5' : 'me-1'
                  } text-sm font600`}
                  onClick={() => setSelectedMenu(button.action)}
                  key={button.id}
                  disabled={
                    forceEnrollment ? true : button.id !== 3 && shouldAllocate
                  }
                >
                  {button.title}{' '}
                </Button>
              ))}
          </Space.Compact>
        )}

        <Space size="middle">
          <div
            className="rightContainer d-inline"
            style={{ cursor: 'pointer' }}
          >
            <Dropdown
              arrow
              menu={{
                items,
              }}
              trigger={['click']}
              size="small"
              className="fw-bold"
              placement="bottomRight"
              overlayStyle={{
                cursor: 'pointer',
              }}
            >
              <Space className="cursor-pointer">
                {loggingOut || (gettingAuthUser && isEmpty(authUser)) ? (
                  <>
                    <LoadingOutlined /> <span>Loading</span>
                  </>
                ) : (
                  <span className="my-auto">
                    <Avatar
                      width={30}
                      height={30}
                      className="my-auto d-inline rounded-square"
                      preview={false}
                    />
                    {!isMobileDevice && (
                      <>
                        <span className="ms-2 me-1">Hi,</span>
                        <span className="text-uppercase text-primary text-sm font600">
                          {authUser.surname}
                        </span>
                      </>
                    )}
                  </span>
                )}

                <CaretDownOutlined />
              </Space>
            </Dropdown>
            <Menu
              className="d-none"
              key={MenuList.BioData.action}
              mode="horizontal"
            >
              <SubMenu
                title={
                  loggingOut || (gettingAuthUser && isEmpty(authUser)) ? (
                    <LoadingOutlined />
                  ) : (
                    <span className="my-auto">
                      <span className="me-2">Hi,</span>
                      <span className="text-uppercase text-primary text-sm font600 me-2">
                        {authUser.surname}
                      </span>
                      <Avatar
                        width={24}
                        height={24}
                        className="my-auto d-inline rounded-circle"
                        preview={false}
                      />
                    </span>
                  )
                }
                key="profile"
                className="px-0"
              >
                <Menu.Item
                  key="my-profile"
                  icon={<UserOutlined />}
                  onClick={() => setSelectedMenu(MenuList.BioData)}
                >
                  My Profile
                </Menu.Item>
                <Menu.Item
                  key="settings"
                  icon={<SettingOutlined />}
                  onClick={() => dispatch(settingActions.setThemeModal(true))}
                >
                  Theme Settings
                </Menu.Item>
                <Menu.Item
                  key="SignOut"
                  icon={loggingOut ? <LoadingOutlined /> : <LogoutOutlined />}
                  onClick={() => dispatch(authActions.logoutUser())}
                  className="font600"
                  danger
                >
                  Log out
                </Menu.Item>
              </SubMenu>
            </Menu>
          </div>
        </Space>
      </Header>
    </>
  );
}

export default MainHeader;
