import { Col, Flex, Row, Tag, Typography } from 'antd';
import { capitalize, isEmpty, isEqual, map, sum, sumBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, Spinner } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { HiCheckCircle, HiXCircle } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import {
  AlertMessage,
  CustomModal,
  SubmitButton,
} from '../../components/common';
import {
  appActions,
  enrollmentActions,
  paymentActions,
  settingActions,
} from '../../config/actions';
import MenuList from '../../config/routes/MenuList';
import NavTag from './NavTag';
import ProgrammeTab from './ProgrammeTab';

const { Text } = Typography;

function Header() {
  const dispatch = useDispatch();
  const { currentStudentProgramme, accountBalance } = useSelector(
    (state) => state.auth
  );
  const { gettingCurrentSemester, currentSemester } = useSelector(
    (state) => state.app
  );
  const { showModal, showAllocateModal } = useSelector(
    (state) => state.setting
  );
  const { invoices, shouldAllocate } = useSelector((state) => state.myInvoice);
  const { financeClearance } = useSelector((state) => state.myTransaction);
  const { forceEnrollment } = useSelector((state) => state.enrollment);
  const isMobileDevice = useMediaQuery({ maxWidth: 767 });
  const [invoiceTotalDue, setInvoiceTotalDue] = useState(0);

  const isCleared = !isEmpty(financeClearance);

  const getInvoices = () => {
    dispatch(
      paymentActions.getMyInvoices({
        student_programme_id: currentStudentProgramme?.student_programme_id,
      })
    );
  };

  const reloadCurrentSemester = () => {
    dispatch(appActions.getCurrentSemester());
    if (!isEmpty(currentStudentProgramme))
      dispatch(enrollmentActions.getCurrentEvents(currentStudentProgramme?.id));
  };

  useEffect(() => {
    if (isEmpty(currentSemester)) {
      reloadCurrentSemester();
    }
    if (isEmpty(invoices)) getInvoices();
  }, []);

  useEffect(() => {
    if (isEmpty(financeClearance)) {
      dispatch(
        paymentActions.getStudentFinanceClearance(currentStudentProgramme?.id)
      );
    }
  }, [financeClearance]);

  useEffect(() => {
    if (!isEmpty(invoices)) {
      let invoiceAmountsDue = 0;

      invoices.forEach((invoice) => {
        const tuitionDue = sumBy(invoice.tuition_invoices, 'amount_due');
        const functionalDue = sumBy(
          invoice.functional_fees_invoices,
          'amount_due'
        );

        const otherFeesDue = sumBy(invoice.other_fees_invoices, 'amount_due');
        const manualsDue = sumBy(invoice.manual_invoices, 'amount_due');

        invoiceAmountsDue += sum([
          tuitionDue,
          functionalDue,
          otherFeesDue,
          manualsDue,
        ]);
      });
      setInvoiceTotalDue(invoiceAmountsDue);
      dispatch(paymentActions.setInvoiceTotalDue(invoiceAmountsDue));

      if (invoiceAmountsDue > 0 && accountBalance > 0) {
        dispatch(settingActions.setShowModal(true));
        dispatch(paymentActions.setShouldAllocateFunds(true));
      } else {
        dispatch(paymentActions.setShouldAllocateFunds(false));
      }
    }
  }, [invoices, accountBalance]);

  // uncomment to enforce enrollment on login

  // useEffect(() => {
  //   if (!isEmpty(currentSemester)) {
  //     if (
  //       currentSemester?.semester &&
  //       !currentSemester?.is_enrolled &&
  //       currentSemester?.force_me_to_enroll
  //     ) {
  //       dispatch(settingActions.setShowAllocateModal(true));
  //       dispatch(enrollmentActions.redirectToEnrollment(true));
  //     } else dispatch(enrollmentActions.redirectToEnrollment(false));
  //   }
  // }, [currentSemester]);

  const handleActions = () => {
    dispatch(settingActions.setShowModal(false));
    dispatch(settingActions.setSelectedMenu(MenuList.MyInvoice));
  };

  const handleEnrollAction = () => {
    dispatch(settingActions.setShowAllocateModal(false));
    dispatch(settingActions.setSelectedMenu(MenuList.SelfEnrollment));
  };

  const primaryColor = process.env.REACT_APP_PRIMARY_COLOR;

  const info = primaryColor || '#3b5999';

  const buttonOptions = [
    {
      id: 1,
      title: 'Current Yr.',
      value: currentSemester?.academic_year,
      color: info,
      span: 6,
    },
    {
      id: 2,
      title: 'Current Sem.',
      value: currentSemester?.semester,
      color: info,
      span: 7,
    },
    {
      id: 3,
      mobileTitle: 'ENROLLED',
      mobileValue: currentSemester?.is_enrolled ? 'YES' : 'NO',
      title: currentSemester?.is_enrolled ? <HiCheckCircle /> : <HiXCircle />,
      value: currentSemester?.is_enrolled ? 'ENROLLED' : 'NOT ENROLLED',
      color: currentSemester?.is_enrolled ? 'success' : 'error',
      span: 6,
    },
    {
      id: 4,
      mobileTitle: 'REGISTERED',
      mobileValue: currentSemester?.is_registered ? 'YES' : 'NO',
      title: currentSemester?.is_registered ? <HiCheckCircle /> : <HiXCircle />,
      value: currentSemester?.is_registered ? 'REGISTERED' : 'NOT REGISTERED',
      color: currentSemester?.is_registered ? 'success' : 'error',
      span: 6,
    },
    {
      id: 5,
      mobileTitle: 'Clearance',
      mobileValue: isCleared ? 'YES' : 'NO',
      title: isCleared ? <HiCheckCircle /> : <HiXCircle />,
      value: isCleared ? 'CLEARED FEES' : 'NOT CLEARED',
      color: isCleared ? 'success' : 'error',
      span: 6,
      hidden: !isCleared,
    },
  ].filter((btn) => !isEqual(btn?.hidden, true));

  return (
    <>
      <ProgrammeTab />
      <div className="border-top border-bottom py-2 px-3 bg-white border-start-0 rounded-0 w-100 d-flex border-end-0">
        <Flex
          justify="space-between"
          align="center"
          wrap
          className="border-0 mb-0 w-100"
        >
          {gettingCurrentSemester && isEmpty(currentSemester) ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <>
              {!isMobileDevice ? (
                <span>
                  {isEmpty(currentSemester) ? (
                    <AlertMessage
                      message="THERE'S CURRENTLY NO RUNNING ACADEMIC YEAR"
                      className="m-0 p-1 px-2 text-sm"
                    />
                  ) : (
                    <>
                      {buttonOptions.map((buttonOption) => (
                        <NavTag
                          key={buttonOption.id}
                          title={buttonOption.title}
                          value={buttonOption.value}
                          color={buttonOption.color}
                        />
                      ))}
                    </>
                  )}
                </span>
              ) : (
                <Row
                  gutter={[8, 8]}
                  className="mb-2 w-100"
                  wrap={false}
                  style={{ overflowX: 'scroll', scrollbarWidth: 0 }}
                >
                  {map(buttonOptions, (tag) => (
                    <Col span={tag.span}>
                      <Tag
                        color={tag?.id > 2 ? tag?.color : 'default'}
                        className="text-center w-100 h-100 py-2 px-2"
                      >
                        <Text strong>
                          {capitalize(tag?.mobileTitle || tag?.title)}
                        </Text>
                        <div className="mt-1 font600">
                          {tag?.mobileValue || tag?.value}
                        </div>
                      </Tag>
                    </Col>
                  ))}
                </Row>
              )}
            </>
          )}
          <span>
            {!isMobileDevice ? (
              <NavTag
                title={
                  <span>
                    <span>TOTAL FEES BAL DUE:</span>
                    <span className="text-danger ms-1">{`${parseInt(
                      invoiceTotalDue,
                      10
                    ).toLocaleString()}/=`}</span>
                  </span>
                }
                color={info}
                value={`BALANCE ON ACCOUNT: ${parseInt(
                  accountBalance,
                  10
                ).toLocaleString()}/=`}
              />
            ) : (
              <Row
                align="center"
                justify="end"
                className="text-sm mt-1 text-uppercase font600"
              >
                <Col flex="auto">
                  <Tag
                    className="w-100 d-flex py-2 justify-content-between"
                    style={{
                      marginRight: 0,
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                  >
                    <span>TOTAL FEES BAL DUE:</span>
                    <span className="text-danger">{`${parseInt(
                      invoiceTotalDue,
                      10
                    ).toLocaleString()}/=`}</span>
                  </Tag>
                </Col>
                <Col flex="auto">
                  <Tag
                    className="w-100 d-flex py-2 justify-content-between"
                    color={info}
                    style={{
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                    }}
                  >
                    <span>BALANCE ON ACCOUNT:</span>
                    <span>{`${parseInt(
                      accountBalance,
                      10
                    ).toLocaleString()}/=`}</span>
                  </Tag>
                </Col>
              </Row>
            )}
          </span>
        </Flex>
      </div>
      {shouldAllocate && showModal && (
        <>
          <CustomModal
            show={shouldAllocate && showModal}
            size="lg"
            title="ACCESS LIMITED!"
            onCloseModal={handleActions}
            backdrop="static"
            keyboard={false}
          >
            <ModalBody>
              <AlertMessage
                variant="info"
                className="fs-3"
                message="Access to your portal has been limited because you
               have pending invoices and unallocated funds"
              />
              <div className="d-flex mt-5">
                <span className="fs-5 text-info">
                  <FaInfoCircle className="me-2 fs-4" />
                  Kindly allocate all funds on your account to the pending
                  invoices
                </span>
              </div>
            </ModalBody>
            <Modal.Footer>
              <SubmitButton
                variant="warning"
                htmlType="button"
                text="ALLOCATE"
                onClick={handleActions}
              />
            </Modal.Footer>
          </CustomModal>
        </>
      )}
      {forceEnrollment && showAllocateModal && (
        <>
          <CustomModal
            show={forceEnrollment && showAllocateModal}
            size="lg"
            title="PENDING ENROLLMENT!"
            onCloseModal={handleEnrollAction}
            backdrop="static"
            keyboard={false}
          >
            <ModalBody>
              <AlertMessage
                variant="info"
                className="fs-3"
                message="Access to your portal has been limited because you
                have not enrolled for the current semester."
              />
              <div className="d-flex mt-5">
                <span className="fs-5 text-info">
                  <FaInfoCircle className="me-2 fs-4" />
                  Kindly enroll for the current semester to regain access.
                </span>
              </div>
            </ModalBody>
            <Modal.Footer>
              <SubmitButton
                variant="warning"
                htmlType="button"
                text="ENROLL"
                onClick={handleEnrollAction}
              />
            </Modal.Footer>
          </CustomModal>
        </>
      )}
    </>
  );
}

export default Header;
